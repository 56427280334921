import { reactive } from "@vue/composition-api";
export default () => {
    const modeInitial = [
        { column: "ASC", label: "ASC" },
        { column: "DESC", label: "DESC" }
    ];
    const sortInitial = {
        column: "id",
        mode: "ASC",
    };
    const pagesInitial = {
        current: 1,
        itemsPerPage: 10,
        totalPage: 0,
        perPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: 'All', value: 0 },
        ]
    };

    let sortMode = reactive(Object.assign({}, modeInitial))
    let sort = reactive(Object.assign({}, sortInitial));
    let pages = reactive(Object.assign({}, pagesInitial));

    const resetSort = () => {
        sortMode = Object.assign(sortMode, modeInitial);
        sort = Object.assign(sort, sortInitial);
        pages = Object.assign(pages, pagesInitial);
    }

    return { sortMode, pages, sort, resetSort };
}
