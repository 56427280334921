import useSort from "@/composition/UseSort";
import {
  onMounted,
  reactive,
  ref,
  computed,
  watch
} from "@vue/composition-api";
// import { vLog } from "@/plugins/util";

export default function(refs, root, service, initialEntity, headers) {
  const { $i18n } = root;
  // paging and filter
  let { pages, sort } = useSort();
  const tableHeaders = reactive(headers);

  // state
  const state = reactive({
    isFristLoad: false,
    loading: false
  });
  const editMode = ref(false);

  const dataList = ref([]);
  const searchTxt = ref("");
  const advanceSearchParam = ref("");
  // form
  const openNewForm = ref(false);
  const isProcessing = ref(false);
  const selectedIndex = ref(null);

  // entity

  let entity = reactive(Object.assign({}, initialEntity));

  // hooks
  onMounted(() => {
    fetchData();
  });

  // computed
  const pageParam = computed(
    () =>
      `?page=${pages.current}&perPage=${pages.itemsPerPage}&sortField=${sort.column}&sortMode=${sort.mode}${advanceSearchParam.value}`
  );

  const filteredList = computed(() => {
    const search = searchTxt.value
      ? searchTxt.value.toLowerCase().trim()
      : null;
    if (!search) {
      return dataList.value;
    }
    const list = dataList.value;
    let filters = [];
    const searchables = searchableHeaders();

    for (const header of searchables) {
      if (header.column) {
        const searchList = list.filter(
          c =>
            c[header.column]
              .toString()
              .toLowerCase()
              .indexOf(search) > -1 && !filters.find(x => x.id === c.id)
        );
        filters = filters.concat(searchList);
      }
    }
    return filters;
    // return list.filter(c => c.description.toLowerCase().indexOf(search) > -1 );
  });
  const fillableHeaders = computed(() => {
    return tableHeaders.filter(c => c.fillable === true);
  });
  const searchableHeaders = () => {
    return tableHeaders.filter(c => c.searchable === true);
  };

  // method
  const toast = (status, message) => {
    if (!message || !refs.weeToastRef) return;
    refs.weeToastRef.toast(message, { color: status ? "success" : "error" });
  };
  const advanceSearch = searchList => {
    if (!searchList || searchList.length == 0) {
      return;
    }
    let seracherString = "";
    for (const item of searchList) {
      seracherString += `&q_${item.key}=${item.value}`;
    }
    advanceSearchParam.value = seracherString;
    fetchData();
  };
  const fetchData = async () => {
    state.loading = true;
    const { response, error } = await service.get(pageParam.value);
    // console.log("UseCrudApi > fetchData", response);
    state.loading = false;
    if (error) {
      toast(false, error);
      return;
    }
    toast(response.status, response.message);
    if (response) {
      if (response.status) {
        dataList.value = response.dataList;
        pages.totalPage = response.appPagination;
      }
    }
    if (!state.isFristLoad) {
      state.isFristLoad = true;
    }
  };
  const onReload = () => {
    // advanceSearchParam.value = [];
    // resetSort();
    window.location.reload();
  };

  const getItemByIndex = index => {
    const itemList = filteredList.value;
    const item = itemList[index];
    if (!item) {
      return;
    }
    return item;
  };

  const onItemClick = index => {
    const item = getItemByIndex(index);
    if (!item) {
      return;
    }
    selectedIndex.value = index;
    entity = Object.assign(entity, item);
    openNewForm.value = true;
    editMode.value = true;
  };

  const onOpenNewForm = () => {
    editMode.value = false;
    selectedIndex.value = null;
    entity = Object.assign(entity, initialEntity);
    openNewForm.value = true;
  };

  // delete
  const onBeforeDeleteItem = (index, isSingle) => {
    refs.weeConfirmRef
      .open(
        $i18n.tc("base.deleteConfirm"),
        $i18n.tc("helper.confirmSeleteText"),
        { color: "error" }
      )
      .then(confirm => {
        if (confirm) {
          if (isSingle) {
            onDeleteItem(index);
          } else {
            onDeleteSelectedItem(index);
          }
        }
      });
  };

  const onDeleteSelectedItem = async idArray => {
    isProcessing.value = true;
    const { response, error } = await service.delete(idArray.join("_"));
    isProcessing.value = false;
    if (error) {
      toast(false, error);
      return;
    }
    if (response && response.status) {
      fetchData();
    }
  };

  const onDeleteItem = async index => {
    const item = getItemByIndex(index);
    if (!item) {
      return;
    }
    isProcessing.value = true;
    const { response, error } = await service.delete(item.id);
    isProcessing.value = false;
    if (error) {
      toast(false, error);
      return;
    }
    if (response && response.status) {
      dataList.value.splice(index, 1);
    }
  };

  const onSave = async () => {
    isProcessing.value = true;
    // const isUpdate = entity.id ? true : false;
    const { response, error } = editMode.value
      ? await service.update(entity)
      : await service.create(entity);
    isProcessing.value = false;
    if (error) {
      toast(false, error);
      return;
    }

    // console.log("UseCrudApi > onSave", response);
    toast(response.status, response.message);
    if (response.errors) {
      const resErrors = response.errors;
      let errs = "";
      for (const key in resErrors) {
        if (resErrors[key]) {
          errs += `${key} : ${resErrors[key]}<br>`;
        }
      }
      toast(response.status, errs);
    }
    toast(response.status, response.message);

    if (!editMode.value && response.entity) {
      dataList.value.push(response.entity);
    }
    if (editMode.value) {
      dataList.value[selectedIndex.value] = Object.assign(
        dataList.value[selectedIndex.value],
        entity
      );
    }
    openNewForm.value = false;
    entity = Object.assign(entity, initialEntity);
  };

  // watcher
  watch(
    () => pages.current,
    () => {
      // (newVal, oldVal) => {
      if (state.isFristLoad) {
        fetchData();
      }
    }
  );
  watch(
    () => pages.itemsPerPage,
    () => {
      if (state.isFristLoad) {
        fetchData();
      }
    }
  );
  watch(
    () => sort.column,
    () => {
      if (state.isFristLoad) {
        fetchData();
      }
    }
  );

  watch(
    () => sort.mode,
    () => {
      if (state.isFristLoad) {
        fetchData();
      }
    }
  );
  const crud = reactive({
    state: state,
    sort: sort,
    pages: pages,
    filteredList: filteredList,
    entity: entity,
    isProcessing: isProcessing,
    searchTxt: searchTxt,
    fillableHeaders: fillableHeaders,
    editMode: editMode,
    fetchData: fetchData,
    onReload: onReload,
    openNewForm: openNewForm,
    onItemClick: onItemClick,
    onOpenNewForm: onOpenNewForm,
    onBeforeDeleteItem: onBeforeDeleteItem,
    onSave: onSave,
    advanceSearch: advanceSearch
  });

  return {
    crud
    // state,
    // sort,
    // pages,
    // filteredList,
    // entity,
    // isProcessing,
    // searchTxt,
    // fillableHeaders,
    // editMode,
    // //method
    // fetchData,
    // onReload,
    // openNewForm,
    // onItemClick,
    // onOpenNewForm,
    // onBeforeDeleteItem,
    // onSave,
    // advanceSearch
  };
}
